@use "styles/accessibility";
@use "styles/ctas";
@use "styles/breakpoints";
@use "styles/colorSchemes";

@keyframes rotate {
  50% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }
}

.root {
  display: block;
  margin: 0 auto;

  @include colorSchemes.dark {
    filter: brightness(0.9);
  }
}

.link {
  @include ctas.anchor-reset;
  outline: none;

  clip-path: url(#bananaClip);

  .shouldJiggle & {
    animation: rotate var(--duration) ease-in-out infinite forwards;
  }

  &.archive {
    --duration: 2s;
  }

  &.today {
    --duration: 4s;
  }

  &.doYou {
    --duration: 3s;
  }
}

.background {
  fill: none;

  .shouldHover .link:hover &,
  .shouldHover .link:focus & {
    fill: var(--blue-01);
  }
}

.label {
  @include accessibility.screen-reader-only;
}
