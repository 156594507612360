@use "styles/breakpoints";

.root {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.bananaNav {
  left: 50%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-45%, -50%);
  width: 130%;

  @include breakpoints.sm-up {
    width: 100%;
  }

  @include breakpoints.md-up {
    max-width: 900px;
  }
}
